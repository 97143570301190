:root {
    --app-primary : #262b40;
    --app-secondary : #be231f;
    --app-primary: #292f88;
    --app-secondary: #d4303d;
    --app-light : #fab029;
    --app-dark : #212529;

    --app-ascide-bg: #262b40;
    --app-ascide-link-bg: #fff;
    --app-ascide-link-color: #fff;
    --app-ascide-link-bg-active: #fff;
    --app-ascide-link-color-active: #be231f;

    --app-ascide-hamb-bg: #262b40;
    --app-ascide-hamb-color: #fff;
    --app-ascide-hamb-bg-active: #be231f;
}

@font-face {
    font-family: 'poppin';
    src: url('./fonts/Poppins-Bold.ttf');
    font-weight: bold;
    font-display: fallback;
  }

  @font-face {
    font-family: 'poppin';
    src: url('./fonts/Poppins-Bold.ttf');
    font-weight: 900;
    font-display: fallback;
  }
  
  @font-face {
    font-family: 'poppin';
    src: url('./fonts/Poppins-SemiBold.ttf');
    font-weight: 700;
    font-display: fallback;
  }
  
  @font-face {
    font-family: 'poppin';
    src: url('./fonts/Poppins-Medium.ttf');
    font-weight: 500;
    font-display: fallback;
  }
  
  @font-face {
    font-family: 'poppin';
    src: url('./fonts/Poppins-Regular.ttf');
    font-weight: 400;
    font-display: fallback;
  }
  
  @font-face {
    font-family: 'poppin';
    src: url('./fonts/Poppins-Light.ttf');
    font-weight: 100;
    font-display: fallback;
  }

  body {
    font-family: poppin !important;
    color: #000;
    background-color: #f9f9f9;
  
  }
  
  body::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  body::-webkit-scrollbar-track {
    background: #d3d3d3;
  }
  
  /* Handle */
  body::-webkit-scrollbar-thumb {
    background: #666666;
  }
  
  /* Handle on hover */
  body::-webkit-scrollbar-thumb:hover {
    background: #666666;
  }



  
.hd-navbar {
    background-color: #ffffff;
    box-shadow: -2px 5px 5px rgb(0 0 0 / 5%);
    /* box-shadow: -2px 5px 5px rgb(0 0 0 / 21%); */
    margin-bottom: 0;
    margin-left: 120px;
    border: none;
    min-height: 50px;
    max-height: 50px;
    border-radius: 0;
    /* border-top: #3dc185 2px solid; */
    padding-top: 4px;
  }
  .hd-navbar-mini {
    margin-left: 0px;
  }
  .hd-navbar>.sidebar-toggle {
    border: none;
    border-right: 1px solid #eee;
    cursor: pointer;
    padding: 4px 4px;
    min-height: 100%;
    display: inline-block;
    transition: none;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    margin-left: 4px;
    margin-top: 4px;
    background: var(--app-ascide-hamb-bg) ;
    color: var(--app-ascide-hamb-color);
  }
  
  .hd-navbar>.sidebar-toggle:hover {
    background: #2b4a7f;
    color:#fff;
  }
  
  .mini-sidebar{
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    display: none;
  }
  
  
  .main-sidebar {
    position: fixed;
    z-index: 9000;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 120px;
    padding-top: 0px;
    box-shadow: 1px 0 1px rgb(0 0 0 / 13%);
    background: var(--app-ascide-bg);
  }
  .sidebar {
    padding-bottom: 10px;
    height: auto;
  }
  
  .sidebar_logo , .sidebar_logo_adm{
    display: block;
    width: 100%;
    text-align: center;
    padding: 5px;
    /* background: #fff; */
    /* box-shadow: -10px 5px 10px rgb(0 0 0 / 25%);*/
  }
  .sidebar_logo>img{
    width: 72px;
    transform: scale(1.5);
  }
  .sidebar_logo_adm>img{
    width: 80px;
  }
  
  .sidebar_logo_mini{
    padding: 0px;
    padding-top: 26px;
    padding-bottom: 26px;
  }
  .sidebar_logo_mini>img{
    width: 42px;
    opacity: 1;
  }
  
  .sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 70%;
    overflow-y: auto;
    margin-top: 26px;
    position: absolute;
    width: 100%;
  }
  
  .sidebar-menu_adm {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    max-height: calc(70vh) !important;
    line-height: 1.22 !important;
    padding-bottom: 32px;
  }
  .sidebar-menu>li.header {
    background: #1a2226;
    overflow: hidden;
    text-overflow: clip;
    padding: 10px 25px 10px 15px;
    font-size: 12px;
    color: #fff;
    background: transparent;
    font-weight: bold;
  }
  .sidebar-menu>li {
    position: relative;
      margin: 0;
      padding: 0;
      transition: none;
      padding: 3px 12px;
  }
  .sidebar-menu>li:hover{
    background: transparent;
  }
  
  .sidebar-menu>li:hover a{
    background: var(--app-ascide-link-bg-active) !important;
      color: var(--app-ascide-link-color-active) !important;
      box-shadow: rgb(0 0 0 / 12%) 0px 4px 10px 0px;
  }
  
  .sidebar-menu .active{
    background: var(--app-ascide-link-bg-active) !important;
    color: var(--app-ascide-link-color-active) !important;
    box-shadow: rgb(0 0 0 / 12%) 0px 4px 10px 0px;
  }
  .sidebar-menu>li>a {
    padding: 8px;
    display: block;
    background: transparent;
    border-bottom: 1px #ffffff17 solid;
    border-left: 3px solid transparent;
    color: #fff;
    text-decoration: none;
    transition: none;
    text-align: center;
    border-radius: 4px;
  }
  
  .sidebar-menu>li>a>.icon {
    font-size: larger;
  }
  .sidebar-menu>li>a>.title {
    display: block;
      font-size: x-small;
      font-weight: 500;
  }

  .cont_log_out{
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    /* box-shadow: -10px -5px 10px rgba(0, 0, 0, 0.16); */
  }
  .btn_log_out {
    border: none;
    color: #f44336;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 66%;
    font-weight: 500;
    background: #00000066;
    margin: 8px;
    border-radius: 4px;
    /* box-shadow: -10px -5px 10px rgb(0 0 0 / 16%); */
  }
  
  .user-panel {
    position: relative;
    width: 100%;
    padding: 10px;
    overflow: hidden;
    color: #fff;
  }
  .user-apnel-cont{
    background: #01010117;
    border-radius: 6px;
    border: #ffffff4a 1px solid;
    padding-top: 4px;
  }
  .user-panel-usr-icn{
    text-align: center;
    font-size: x-large;
    padding: 0px;
  }
  .user-panel-usr-data{
    line-height: 1rem;
    padding: 6px;
    background: #fff;
    color: #000;
    text-align: center;
    margin-top: 4px;
    border-radius: 6px;
    box-shadow: 0px -3px 5px rgb(0 0 0 / 17%);
    margin-bottom: -1px;
  }
  .user-panel-usr-data>p{
    font-size: small;
    margin: 2px;
    color: #535353;
  }
  .user-panel-usr-data>b{
    font-size: small;
  }
  
  .user_img{
    width: 70px;
    height: 70px;
    border-radius: 100%;
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 23%);
    border: #fff 1px solid;
    padding: 2px;
    object-fit: cover;
  }
  
  .user_img::after{
    border-radius: 100%;
    content: "";
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    pointer-events: none;
    display: none;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    z-index: 2;
    display: block;
    box-shadow: inset 0 0 0 2px white;
  }
  
  .rw-cont-mini{
    margin-left: 0px;
  }
  
  .no-arrow::after {
    display:none !important;
  }
  
  .rw-cont{
    margin: 0px;
    margin-top: 0px;
    min-height: 550px;
    
  }
  

  .bg_login{
    bottom: -10px;
    left: -10px;
    height: 102%;
    width: auto;
    position: absolute;
  }


  .icon-item-round{
    font-size: 16px;
    background: var(--app-secondary);
    width: 32px;
    display: inline-block;
    height: 32px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    padding-top: 3px;
    position: relative;
  }

  .home-banner, .home-box {
    border: 1px solid #e4e6eb;
    border-radius: 8px;
    box-shadow: -2px 5px 5px rgb(0 0 0 / 5%);
  }

  .inpt_search{
    display: inline-flex !important;
    width: 94% !important;
    border-radius: 16px !important;
    box-shadow: 0 8px 6px -6px #00000036;
    background-color: #edf0f3 !important;
    font-size: small !important;
  }
  .inpt_search:hover{
    border: var(--app-primary) 1px solid !important;
    background-color: #fff !important;
    padding-left: 32px !important;
  }
  
  .btn_search{
    background-color: var(--app-primary) !important;
    color: #fff !important;
    border-radius: 50% !important;
    padding: 2px 8px !important;
    margin-left: -33px;
    margin-top: 0px;
    width: 34px;
    height: 34px;
  }
  
  .btn_rnd{
    background: var(--app-primary);
    color: #fff;
    border-radius: 19px;
    padding: 6px 16px;
    margin-top: -6px;
  }
  .btn_search:hover , .btn_rnd:hover {
    color: var(--app-secondary) !important;
    background-color: #fff !important;
  }


  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    isolation: isolate;
    color: #000 !important;
    border-color: #00000014;
  }
  
  
  .nav-tabs .nav-link.active {
      color: #fff;
      background-color: var(--app-primary);
      border-color: #00000014;
  }

  .nav-tabs .nav-link {
    color: var(--app-secondary);
}


.content-editor-input{
  background: transparent ;
    border-radius: 0px;
    border: #000 2px solid;
    margin-bottom: 4px;
}
.content-editor-input:focus {
  color: #212529 !important;
}
.content-editor-label{
  background: #000;
    color: #fff;
    padding: 2px 10px;
}

.content-editor-input::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.content-editor-input::-webkit-scrollbar-track {
  background: #d3d3d3;
}

/* Handle */
.content-editor-input::-webkit-scrollbar-thumb {
  background: #666666;
}

/* Handle on hover */
.content-editor-input::-webkit-scrollbar-thumb:hover {
  background: #666666;
}

.ql-editor {
  font-family: poppin !important;
}

.ql-container {
  font-size: 1rem !important;
}

.header{
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.3)!important;
    z-index: 999;
    position: relative;
}
#h-menu{

}

#h-menu .nav-link{
  color: var(--app-dark);
  font-size: .9rem;
  white-space: pre-line;
  padding: 1em 1.5em;
  font-weight: 900;
  text-transform: uppercase;
}

#h-menu .active , #h-menu .nav-link:hover{
  color: var(--app-secondary);
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    border-radius: 0px;
    border: none;
    padding-bottom: 26px;
    border-bottom: var(--app-primary) 2px solid;
}

.dropdown-item.active, .dropdown-item:active {
  text-decoration: none;
  background-color: transparent;
  font-weight: 900;
}




.menu-social-link{
  font-size: 28px;
  color: var(--app-primary);
}

.menu-footer-links{
  display: block;
  font-weight: 700;
  color: var(--app-dark);
}

footer{
  border-width: 8px;
    border-style: solid;
    border-image: linear-gradient( to left, #d5323d, rgb(41 47 136 / 30%) ) 100% 1;
    border-bottom: 0px;
}